import React from "react"

import * as ApiClient from "../../../common/api_client"

export const intro = () => (
  <p>
    The ENCODE Encyclopedia comprises two levels of epigenomic and transcriptomic annotations (<strong>Figure 1</strong>). The ground level
    includes annotations such as peaks and quantifications for individual data types produced by the ENCODE uniform processing pipelines.
    The integrative level contains annotations generated by integrating multiple ground-level annotations. The core of the integrative level
    is the Registry of candidate cis-Regulatory Elements (cCREs) which are displayed in SCREEN, a web-based visualization engine designed
    specifically for the Registry. SCREEN allows the user to explore cCREs and investigate how these elements relate to other Encyclopedia
    annotations and raw ENCODE data.
  </p>
)

export const registry1 = () => (
  <p>
    cCREs are the subset of representative DNase hypersensitivity sites (rDHSs) supported by either histone modifications (H3K4me3 and
    H3K27ac) or CTCF-binding data. We start with 93 million individual DHSs across 706 DNase-seq profiles in human and 20 million individual
    DHSs from 173 DNase-seq profiles in mouse. For each respective species, we iteratively cluster the DHSs across all profiles and select
    the DHS with the highest signal (read depth normalized signal) as the rDHS for each cluster. This iterative clustering and selection
    process continues until it results in a list of non-overlapping rDHSs—2.2 million rDHSs in human and 1.2 rDHSs in mouse—representing all
    DHSs (<strong>Figure 3</strong>). We then further selected rDHSs with high DNase signal in at least one biosample (defined as a Z-score
    `{">"}` 1.64, see details on defining high signal below). Finally, from this subset of high signal rDHSs, we selected all elements that
    were also supported by high H3K4me3, H3K27ac, and/or CTCF ChIP-seq signals in concerted biosamples (i.e., samples with complementary
    assay coverage). This resulted in a total of 926,535 human cCREs and 339,815 mouse cCREs (<strong>Figure 2</strong>).
  </p>
)

export const registry2 = () => (
  <p>
    For each rDHS, we computed the Z-scores of the log10 of DNase, H3K4me3, H3K27ac, and CTCF signals in each biosample with such data.
    Z-score computation is necessary for the signals to be comparable across biosamples because the uniform processing pipelines for
    DNase-seq and ChIP-seq data produce different types of signals. The DNase-seq signal is in sequencing-depth normalized read counts,
    whereas the ChIP-seq signal is the fold change of ChIP over input. Even for the ChIP-seq signal, which is normalized using a control
    experiment, substantial variation remains in the range of signals among biosamples.
  </p>
)

export const registry3 = () => (
  <p>
    To implement this Z-score normalization, we used the UCSC tool bigWigAverageOverBed to compute the signal for each rDHS for a DNase,
    H3K4me3, H3K27ac, or CTCF experiment. For DNase and CTCF, the signal was averaged across the genomic positions in the rDHS. The signals
    of H3K4me3 and H3K27ac were averaged across an extended region—the rDHS plus a 500-bp flanking region on each side—to account for these
    histone marks at the flanking nucleosomes. We then took the log10 of these signals and computed a Z-score for each rDHS compared with
    all other rDHSs within a biosample. rDHSs with a raw signal of 0 were assigned a Z-score of -10. For all analysis we defined "high
    signal" as a Z-score greater than 1.64, a threshold corresponding to the 95th percentile of a one-tailed test. We define a max-Z of a
    rDHS as the maximum z-score for a signal across all surveyed biosamples.
  </p>
)

export const classif1 = () => (
  <p>
    Many uses of cCREs are based on the regulatory role associated with their biochemical signatures. Thus, we putatively defined cCREs in
    one of the following annotation groups based on each element’s dominant biochemical signals across all available biosamples. Analogous
    to GENCODE's catalog of genes, which are defined irrespective of their varying expression levels and alternative transcripts across
    different cell types, we provide a general, cell type-agnostic classification of cCREs based on the max-Zs as well as its proximity to
    the nearest annotated TSS:
  </p>
)

export const classif2 = () => (
  <ol type="1">
    <li>
      <em>cCREs with promoter-like signatures (cCRE-PLS)</em> fall within 200 bp (center to center) of an annotated GENCODE TSS and have
      high DNase and H3K4me3 signals (evaluated as DNase and H3K4me3 max-Z scores, defined as the maximal DNase or H3K4me3 Z scores across
      all biosamples with data; see Methods).
    </li>
    <li>
      <em>cCREs with enhancer-like signatures (cCRE-ELS)</em> have high DNase and H3K27ac max-Z scores and must additionally have a low
      H3K4me3 max-Z score if they are within 200 bp of an annotated TSS. The subset of cCREs-ELS within 2 kb of a TSS is denoted proximal
      (cCRE-pELS), while the remaining subset is denoted distal (cCRE-dELS).
    </li>
    <li>
      <em>DNase-H3K4me3 cCREs</em> have high H3K4me3 max-Z scores but low H3K27ac max-Z scores and do not fall within 200 bp of a TSS.
    </li>
    <li>
      <em>CTCF-only cCREs</em> have high DNase and CTCF max-Z scores and low H3K4me3 and H3K27ac max-Z scores.{" "}
    </li>
  </ol>
)

export const classif3 = () => (
  <p>
    In addition to the cell type-agnostic classification described above, we evaluated the biochemical activity of each cCRE in each
    individual cell type using the corresponding DNase, H3K4me3, H3K27ac, and CTCF data (<strong>Figure 3</strong>). All cCREs with low
    DNase Z-scores in a particular cell type are bundled into one “inactive” state for that cell type; the remaining “active” cCREs are
    divided into eight states according to their epigenetic signal Z-scores, producing nine possible states in total. The three groups
    described above—cCRE-PLS, cCRE-ELS, and CTCF-only cCRE—apply to the active cCREs within a particular cell type. Two additional groups
    are defined with respect to individual cell types: an <u>inactive</u> group, containing all cCREs in the inactive state, and a{" "}
    <u>DNase-only</u> group, containing cCREs with high DNase Z-scores but low H3K4me3, H3K27ac, and CTCF Z-scores within the cell type.
    Importantly, while the classification schemes in <strong>Figures 2 and 3</strong> place each cCRE into only one activity group, the
    signal strengths for all recorded epigenetic features are retained for each cCRE in the Registry, and these can be used for customized
    searches by users.
  </p>
)

export const classif4 = () => (
  <p>
    We also attempt to make group assignments for cCREs in a particular biosample not fully covered by the four core assays, making some
    approximations. For samples with DNase data, we classify elements using the available marks. For example, if a sample lacks H3K27ac its
    cCREs will be assigned to the PLS and DNase-H3K4me3 groups but not the pELS or dELS groups. For biosamples lacking DNase data, we do not
    have the resolution to identify specific elements. Therefore, for these biosamples, we simply label the cCRE as having a high or low
    signal for every available assay. In these biosamples, cCREs with low H3K4me3, H3K27ac, or CTCF signals were labelled “unclassified”
    because we were unable to classify them as low-DNase without DNase data. In both SCREEN and in downloadable files biosamples lacking
    data are clearly labeled as such.
  </p>
)

export const genomicFootprint = () => (
  <p>
    We analyzed the percentage of the genome covered by each group of cCREs, considering only regions of the genome which are not
    blacklisted (~3.2 billion bases for human and 2.7 billion bases for mouse). In total, 7.9% of the mappable genome is covered by cCREs
    (0.3% by cCREs-PLS, 1.1% by cCREs-pELS, 5.8% by cCREs-dELS, 0.2% by DNase-H3K4me3 cCREs and 0.7% by CTCF-only cCREs) and 3.4% of the
    mappable mouse genome is covered by cCREs (<strong>Figure 4</strong>). The lower coverage for mouse is due to the smaller number of cell
    types with data to define cCREs.
  </p>
)

export const additionalProperties = () => (
  <span>
    <p>We performed additional analysis on including:</p>
    <ul>
      <li>Comprehensiveness of the Registry</li>
      <li>Conservation</li>
      <li>Overlap with other epigenomic and transcriptomic data</li>
      <li>Experimental validation from mouse transgenic assays</li>
    </ul>
    <p>
      Details and results from these analyses can be found at our companion website,{" "}
      <a href="http://encyclopedia.wenglab.org/">http://encyclopedia.wenglab.org/</a>.
    </p>
  </span>
)

export const groundLevel = () => (
  <p>
    In addition to hosting the Registry of cCREs, SCREEN also integrates the ground level Encyclopedia annotations. Under the{" "}
    <em>cCRE Details page</em> for each cCRE are the overlapping ground level annotations with links to their derived experiments.
    Additionally, some annotations, such as histone mark and TF ChIP-seq peaks, gene expression, and RAMPAGE transcription levels are
    highlighted further with specific tabs under the <em>cCRE Details</em> page.
  </p>
)

export const gwas1 = () => (
  <p>
    We downloaded associations reported in the{" "}
    <a href="https://www.ebi.ac.uk/gwas/">NHGRI-EBI genome-wide association studies (GWAS) catalog</a> as of January 1, 2019. Because mixed
    populations complicate linkage disequilibrium (LD) structures, we only selected studies that were performed on a single population. For
    each study, we downloaded all reported SNPs (<em>p</em> &lt; 10<sup>-6</sup>), even those that were just under genome wide significance.
    We then intersected all reported SNPs and SNPs in high LD (
    <em>
      r<sup>2</sup>
    </em>{" "}
    &gt; 0.7), with GRCh38 cCREs. These results are available through the SCREEN GWAS app.
  </p>
)

export const gwas2 = () => (
  <p>
    For studies with more than 25 lead SNPs, we performed biosample enrichment analysis. For each study, we generated a matching set of
    control SNPs as follows: for each SNP in the study (<em>p</em>-value &lt; 10<sup>-6</sup>) we selected a SNP on Illumina and Affymetrix
    SNP chips that fell within the same population-specific minor allele frequency (MAF) quartile and the same distance to TSS quartile. We
    repeated this process 500 times, generating 500 random control SNPs for each GWAS SNP. Then, for both GWAS and control SNPs, we
    retrieved all SNPs in high linkage disequilibrium (LD{" "}
    <em>
      r<sup>2</sup>
    </em>{" "}
    &gt; 0.7), creating LD groups. This method was adapted and modified from the{" "}
    <a href="https://github.com/robertkleinlab/uesEnrichment">Uncovering Enrichment through Simulation (UES)</a> method developed by the
    Klein Lab (Hayes <em>et al.</em> 2015).
  </p>
)

export const gwas3 = () => (
  <p>
    To assess whether the cCREs in a biosample were enriched in the GWAS SNPs, we intersected GWAS and control LD groups with cCREs with an
    H3K27ac Z-score `{">"}` 1.64 in the biosample. To avoid overcounting, we pruned the overlaps, counting each LD group once per biosample.
    We modified the UES method by calculating p-values from Z-scores for performing statistical testing. We calculated enrichment for
    overlapping cCREs by comparing the GWAS LD groups with the 500 matched controls. Finally, we applied a false discovery rate threshold of
    5% to each study.
  </p>
)

const figureLegends = [
  () => (
    <span>
      <strong>Figure 1 | Overview of the ENCODE Encyclopedia with a Registry of candidate cis-regulatory elements.</strong> The Encyclopedia
      consists of ground-level and integrative-level of annotations that use data processed by the uniform processing pipelines. SCREEN
      integrates all levels of annotations and raw data and allows users to visualize them in the UCSC genome browser.
    </span>
  ),
  () => (
    <span>
      <strong>Figure 2 | Selection and classification of cCREs to build the Registry of candidate cis-regulatory elements.</strong> We begin
      by filtering and clustering DNase peaks to create representative DHSs (rDHSs). We then select those rDHSs with high DNase signal
      (maximal Z-score or max-Z across all biosamples with data, see Methods) and high signal for at least one other assay (H3K4me3, H3K27ac
      or CTCF) to be cCREs. In total, we define 926,535 cCREs in human and 339,815 cCREs in mouse. Based on combinations of signal and
      genomic context, we classify cCREs into one of these groups: PLS, pELS, dELS, DNase-H3K4me3, or CTCF-only, and their counts are
      indicated (k: thousand; M: million).
    </span>
  ),
  () => (
    <span>
      <strong>Figure 3 | Classification of cCREs in GM12878.</strong> <strong>a</strong>, Classification of seven groups of cCREs (PLS,
      pELS, dELS, DNase-H3K4me3, CTCF-only, DNase-only, or low-DNase) in GM12878 by their states of high or low H3K4me3, H3K27ac, or CTCF
      Z-scores in GM12878. <strong>b</strong>, Number of each group of GM12878 cCREs.
    </span>
  ),
  () => (
    <span>
      <strong>Figure 4 | Genomic coverage of cCREs.</strong> Percentages of the human GRCh38 genome (left) and mouse mm10 genome (right)
      comprising cCREs stratified by cCRE group: PLS in red, pELS in orange, dELS in yellow, DNase-H3K4me3 in pink and CTCF-only in blue.
    </span>
  ),
  () => (
    <span>
      <strong>Figure 5 | Enrichment of GWAS SNPs in biosample specific cCREs.</strong> SCREEN reports the percentage of LD blocks of a GWAS
      with at least one SNP overlapping a cCRE. Biosamples are ranked by enrichment of SNP-overlapping cCREs with high H3K27ac signals. Top
      cell and tissue types are displayed here for each study.
    </span>
  ),
]

export const citation = () => (
  <ul>
    <li>
      ENCODE Project Consortium, Jill E. Moore, Michael J. Purcaro, Henry E. Pratt, Charles B. Epstein, Noam Shoresh, Jessika Adrian, et al.
      2020. “Expanded Encyclopaedias of DNA Elements in the Human and Mouse Genomes.” <i>Nature</i> 583 (7818): 699–710.
    </li>
  </ul>
)

export const figure = (num, alt, style = {}) => {
  const imgSrc = "/about/images/figure" + num + ".png"
  return (
    <div className={"panel panel-default"}>
      <div className="panel-body">
        <figure className={"figure"}>
          <img src={ApiClient.StaticUrl(imgSrc)} className={"figure-img img-fluid rounded img-responsive"} alt={alt} style={style} />
          <figcaption className={"figure-caption"}>{figureLegends[num - 1]()}</figcaption>
        </figure>
      </div>
    </div>
  )
}
