import React from "react"
import { useGetDownloadFileUrl } from "./utils"

const Download = ({ primaryurl, secondaryurl, title, number, bar }) => {
  const url = useGetDownloadFileUrl(primaryurl, secondaryurl)
  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ width: "100%", height: "0.2em", backgroundColor: bar }} />
      <span style={{ textAlign: "center", fontSize: "1.0em", fontWeight: "bold" }}>{title}</span>
      <br />
      <span style={{ textAlign: "center", fontSize: "0.9em" }}>({number.toLocaleString()})</span>
      <br />
      <a href={url} download>
        <svg className="bi bi-download" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            d="M.5 8a.5.5 0 01.5.5V12a1 1 0 001 1h12a1 1 0 001-1V8.5a.5.5 0 011 0V12a2 2 0 01-2 2H2a2 2 0 01-2-2V8.5A.5.5 0 01.5 8z"
            clipRule="evenodd"
          />
          <path
            fillRule="evenodd"
            d="M5 7.5a.5.5 0 01.707 0L8 9.793 10.293 7.5a.5.5 0 11.707.707l-2.646 2.647a.5.5 0 01-.708 0L5 8.207A.5.5 0 015 7.5z"
            clipRule="evenodd"
          />
          <path fillRule="evenodd" d="M8 1a.5.5 0 01.5.5v8a.5.5 0 01-1 0v-8A.5.5 0 018 1z" clipRule="evenodd" />
        </svg>
        <span style={{ fontSize: "0.9em" }}>&nbsp;Download</span>
      </a>
    </div>
  )
}
export default Download
